import styled from 'styled-components';
import React from "react";
import TestImage from '../../images/home/vastech-bg.webp';
import { SubHeader, Header, Body } from '../Typography';
import { NewLink } from "../Buttons";
import { Colors, Breakpoints } from '../../theme';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
  justify-content: center;
  align-items: center;
  margin: 32px 0;

  @media only screen and (max-width: ${Breakpoints.tabletSmall}) {
	flex-direction: column;
	margin: 24px;
  }

	@media only screen and (max-width: ${Breakpoints.mobile}) {
		margin: 16px;
	}
`;

const Image = styled.img`
		width: 50%;
	height: 100%;

	@media only screen and (max-width: ${Breakpoints.tabletSmall}) {
		width: 100%;
	}
`;

const ContentBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: ${({ reverse }) => reverse ? '64px 64px 64px 0' : '64px 0 64px 64px'};
	justify-content: center;
	

	@media only screen and (max-width: ${Breakpoints.tabletSmall}) {
		padding: 32px 0px;
	}

	@media only screen and (max-width: ${Breakpoints.mobile}) {
		padding: 16px 0px;
	}
`;

const Eyebrow = styled.span`
	margin-bottom: 8px;
`;

const Spacer = styled.div`
  height: ${( {height}) => height ? height : '8px'};
`;

const CTAWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	flex-wrap: wrap;
`;

const Item = styled.li`
  /* list-style-type: none; */
  color: ${Colors.Primary};
  margin: 4px;
`;

const List = styled.ul`
  margin: 16px;
`;


const _checkNumber = (number) => {
	if (number % 2 === 0) {
		//number is even
		return true;
	} else {
		return false;
	}
}

const TextImageBlock = ({ className, data, style, index }) => {

	let isEven = _checkNumber(index);
	let imageCheck = data.image ?? TestImage;

	return (
		<Wrapper
			id={data.id}
			className={className}
			reverse={isEven}
			style={style}>
			<Image src={imageCheck} alt="alt text" />

			<ContentBlock reverse={isEven}>
				{data.subheader && <Eyebrow><SubHeader color={Colors.Commercial}>{data.subheader}</SubHeader></Eyebrow>}
				{data.title && <Header h3 color={Colors.Primary}>{data.title}</Header>}
				{data.body && <Body>{data.body}</Body>}
				{data.items &&
					<List>
						{data.items.map((data, index) => {
							return (
								<Item data={data} key={'itemKey' + index} index={index}>{data}</Item>
							);
						})}
					</List>
				}
                <Spacer height="32px"/>
				<CTAWrapper>
					{data.commercialLink &&
						<NewLink to={'/commercial/#' + data.href}>{'For Commercial'}</NewLink>
					}
					<Spacer/>
					{data.residentialLink &&
						<NewLink residential to={'/residential/#' + data.href}>{'For Residential'}</NewLink>
					}
				</CTAWrapper>
			</ContentBlock>
		</Wrapper>
	);
}

export default TextImageBlock;