import Image1 from '../images/home/vastech-hairback.webp';
import Image2 from '../images/home/vastech-security.webp';
import CableImage from '../images/commercial/vastech-cable.webp';
import SignageImage from '../images/commercial/vastech-signage.webp';
import SecurityImage from '../images/commercial/vastech-security.webp';
import AudioVideoImage from '../images/commercial/vastech-audio-video.webp';

let CommercialData = {
  hero: {
    title: 'Commercial Audio Visual Installations.',
    subheader: "For Commercial Customers",
    body: "Need AV Solutions for your business? We’ll customize your commercial solution, teach you how to use it, and stay in touch for as long as you need us."
  },

  textblocks: [
    {
      subheader: 'Audio & Video',
      title: "Bring The Boom to Your Business.",
      id: "audio-video",
      body: 'Killer sound systems and video displays aren’t just for music venues or night clubs. We’ll customize a commercial AV experience for your retail store, restaurant, hotel, bar, small business, or corporate office. \n\nEngage customers, boost your brand, and rake in more revenue with:',
      items: [
        "Sound Systems & Zone Audio",
        "HD Screens",
        "Video Walls / Projectors",
        "Interactive Displays",
        "Video Conferencing",

      ],
      image: AudioVideoImage
    },
    {
      subheader: 'Security',
      title: 'See your Business from Every Angle.',
      id: "security",
      body: 'Did you know that a commercial security system can reduce the risk of theft and vandalism by up to 95%? Workplace injury. Property damage. Burglary. They all go down with a high-quality security system.\n\nProtect your property and your employees with:',
      items: [
        "HD Video Surveillance",
        "Access Control",
        "Motion Sensors & Alarms",
        "Smart Automation",
      ],
      image: SecurityImage

    },
    {
      subheader: 'Digital Signage',
      title: 'Look on the Bright Side.',
      id: "digital-signage",
      body: 'According to companies like Intel and Microsoft, digital signage has been proven to boost sales by up to 33%, increase customer engagement by up to 32%, and reduce perceived wait times by 35%. Sounds good, right? \n\n Let’s customize interactive experiences at your business with digital signage that:',
      items: [
        "Attracts Customers",
        "Answers Questions",
        "Promotes Products",
        "Drives Sales",
        "Supports Customer Service"
      ],
      image: SignageImage

    },
    {
      subheader: 'Networking & Communications',
      title: 'Be a Better Brand.',
      id: "network",
      body: 'Your business runs better when you can connect to employees, customers, and partners at a moment’s notice. Employee productivity goes up. Customer complaints go down. Everyone wins.\n\nWhich commercial networking and communications system will benefit your business the most?',
      items: [
        "Network Infrastructure",
        "Wi-Fi Access Points",
        "VoIP Phone Systems",
        "POS Wiring & Configuration",
        "24/7 Customer Support",
      ],
      image: CableImage
    }
  ],
};

export { CommercialData };
